/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/variables.scss';
import './styles/reset.scss';
import './styles/app.scss';
import './styles/font.scss';
import './styles/responsive.scss';

import 'bootstrap/dist/css/bootstrap-grid.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery'
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js'
import 'lightbox2/dist/css/lightbox.min.css'

import './js/main';


import Swiper, { Navigation } from 'swiper';

const itineraires_map = document.querySelector(".modal_container");
const itineraires_map_open = document.querySelectorAll(".trigger-map");
const activites = document.querySelectorAll("#tour_types .card-content");
const header_lang_select = document.getElementById("hero_language");
const header_lang_fr = document.getElementById("french");
const header_lang_de = document.getElementById("deutsch");
const switch_season = document.getElementById("switch");
const switch_season_text = document.getElementById("change-season-text");

itineraires_map_open.forEach(trigger => trigger.addEventListener("click", toggleModal));

let HamburgerMenu = document.getElementById("openmenu_hamburger");

HamburgerMenu.addEventListener('click', () => {
  OpenCloseSVG();
  ShowMobileNavbar();
});

function ShowActivities() {
  let mobile_activities = document.getElementById("mobile-tour_types");
  let chevron = document.getElementById("chevron");
  if (mobile_activities.style.display === "none") {
    mobile_activities.style.display = "block";
    chevron.style.transform = "rotate(180deg)";
  } else {
    mobile_activities.style.display = "none";
    chevron.style.transform = "rotate(360deg)";
  }
};

function ShowMobileNavbar() {
  let mobile_nav = document.getElementById("mobile-navigation");
  if (mobile_nav.classList.contains("navigation_active")) {
    mobile_nav.classList.remove("navigation_active");
    // document.getElementById("sticky").style.background = "none";
  } else {
    mobile_nav.classList.add("navigation_active");
    // document.getElementById("sticky").style.background = "#fff";
  }
};

function OpenCloseSVG() {
  HamburgerMenu.classList.toggle("active");
};

function toggleModal() {
  itineraires_map.classList.toggle("active");
};

const hero = new Swiper('.hero-swiper', {
  loop: true,
  allowTouchMove: false,
  autoplay: {
    delay: 3000,
  },
});

activites.forEach(activite => {
  activite.addEventListener("click", () => {
    resetLinks();
    activite.classList.add("activity_active");
  })
});

function resetLinks() {
  activites.forEach(activite => {
    activite.classList.remove("activity_active");
  });
}

const regions = new Swiper('.regions-swiper', {
  modules: [Navigation],
  spaceBetween: 40,
  initialSlide: 1,
  watchSlidesProgress: true,

  navigation: {
    nextEl: ".regions-swiper .swiper-button-next",
    prevEl: ".regions-swiper .swiper-button-prev",
  },

  breakpoints: {
    768: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 3,
    }
  }
});


const regions_mobile = new Swiper('.mobile-regions-swiper', {
  modules: [Navigation],
  spaceBetween: 40,
  slidesPerView: 1,
  initialSlide: 1,

  navigation: {
    nextEl: ".mobile-regions-swiper .swiper-button-next",
    prevEl: ".mobile-regions-swiper .swiper-button-prev",
  },
});

const idees = new Swiper('.idees-swiper', {
  modules: [Navigation],
  navigation: {
    nextEl: ".idees-swiper .swiper-button-next",
    prevEl: ".idees-swiper .swiper-button-prev",
  },
  spaceBetween: 40,
  initialSlide: 3,
  watchSlidesProgress: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    }
  }
});

const outils_diffusion_mobile = new Swiper('.outils_diffusion-swiper', {
  spaceBetween: 40,
  slidesPerView: 1,
  loop: true,
});

const guides = new Swiper('.guides-swiper', {
  spaceBetween: 40,
  slidesPerView: 1,
  loop: true,
});

const tour_images = new Swiper('.tour-swiper', {
  spaceBetween: 40,
  slidesPerView: 1,
  loop: true,
});

var hero_swiper = new Swiper(".tour_types .slide-content", {
  modules: [Navigation],

  navigation: {
    nextEl: ".tour_types .swiper-button-next",
    prevEl: ".tour_types .swiper-button-prev",
    disabledClass: "swiper_button_disabled",
  },
  breakpoints: {
    0: {
      slidesPerView: 2,
    },
    480: {
      slidesPerView: 3,
    },
    576: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 4,
    },
  },
});

document.querySelectorAll('.headerlink').forEach(link => {
  if (window.location.href.includes(link.href)) {
    link.classList.add("active");
  } else {
    link.classList.remove("active");
  }
});

header_lang_select.addEventListener("change", () => {
  if (header_lang_select.value == 'fr') {
    header_lang_fr.style.display = "none";
    header_lang_de.style.display = "block";
  } else if (header_lang_select.value == 'de') {
    header_lang_de.style.display = "none";
    header_lang_fr.style.display = "block";
  }
});